import React from 'react';
import { NavLink } from 'react-router-dom';

function Footer(props) {
    return (
        <footer class="edu-footer footer-dark bg-image footer-style-2">
        <div class="footer-top footer-top-2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="edu-footer-widget">
                            <div class="logo w-165">
                                <NavLink to={"/"}>
                                    <img class="logo-light" src={require("../Assets/img/logo/logo.png")}
                                        alt="Corporate Logo" width="150" />
                                </NavLink>
                            </div>
                            <p class="description">At Neya Dermatology & Aesthetics, we strive to provide a perfect balance of functional
                                medicine, latest technology, therapeutic care & trusted comfort from the minute you walk
                                into our clinic.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="description edu-footer-widget m-0">
                            <h4 class="widget-title mt-0">Info</h4>
                            <ul class="information-list mt-40 mb-4 inner">
                                <li><span>Add:</span>Hill Top by Ista Homes, 3rd Floor, Plot No.298, Road No.78, Jubilee Hills, Hyderabad,
                                    Telangana - 500033</li>
                                <li><span>Call:</span><a href="tel:+917832009999">+91-7832009999</a></li>
                                <li><span>Email:</span><a href="mailto:info@Edu5.com"
                                        target="_blank">contact@neyaclinic.com</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <div class="edu-footer-widget quick-link-widget mt-4">
                            <h4 class="widget-title">Links</h4>
                            <div class="inner">
                                <ul class="footer-link link-hover">
                                    <li><NavLink to={"/contact"}>Contact Us</NavLink></li>
                                    <li><NavLink to={"/about"}>About Us</NavLink></li>
                                    <li><NavLink to={"/services"}>Services</NavLink></li>
                                    <li><NavLink to={"/blogs"}>Blog</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mob-hide-md">
                        <div class="edu-footer-widget">
                            <h4 class="widget-title">Contacts</h4>
                            <div class="inner">
                                <p class="description">Enter your email address to register to our newsletter
                                    subscription</p>
                                <form class="rnt-contact-form rwt-dynamic-form1" id="contact-form" method="POST"
                                    action="subscriber.php">
                                    <div class="input-group footer-subscription-form m-0">
                                        <div class="form-group col-7 p-0">
                                            <input type="email" name="contact-email" id="contact-email"
                                                class="h-50px" placeholder="Enter your email" />
                                        </div>
                                        <div class="form-group col-5">
                                            <button class="rn-btn edu-btn btn-medium submit-btn d-flex align-items-center" name="submit"
                                                type="submit">Subscribe <i class="icon-4"></i></button>
                                        </div>
                                    </div>
                                </form>
                                {/* <ul class="social-share icon-transparent">
                                    <li><a href="#" class="color-fb"><i class="icon-facebook"></i></a></li>
                                    <li><a href="#" class="color-ig"><i class="icon-instagram"></i></a></li>
                                    <li><a href="#" class="color-linkd"><i class="icon-linkedin2"></i></a></li>
                                    <li><a href="#" class="color-twitter"><i class="icon-twitter"></i></a></li>
                                    <li><a href="#" class="color-yt"><i class="icon-youtube"></i></a></li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area d-none">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <p>Copyright 2024 <a href="index.html">Edu5</a>
                                Designed By <a href="https://pixelarsolutions.com">PixelarSolutions</a>. All Rights
                                Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
}

export default Footer;