import React, { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

import './App.css';
import './Assets/css/style.css';
import './Assets/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';
import FooterStrip from './Components/FooterStrip';

const Home = React.lazy(() => import('./Pages/Home'));
const Blogs = React.lazy(() => import('./Pages/Blogs'));
const About = React.lazy(() => import('./Pages/About'));
const Contact = React.lazy(() => import('./Pages/Contact'));
const Services = React.lazy(() => import('./Pages/Services'));
const BlogDetails = React.lazy(() => import('./Pages/BlogDetails'));
const Appointment = React.lazy(() => import('./Pages/Appointment'));
const VirtualTour = React.lazy(() => import('./Pages/VirtualTour'));
const RouteToPage = React.lazy(() => import('./Pages/RouteToPage'));
const ThankYouCard = React.lazy(() => import('./Components/ThankYouCard'));
const ServicesDetails = React.lazy(() => import('./Pages/ServicesDetails'));
const DoctorDetails = React.lazy(() => import('./Pages/DoctorDetails'));

const options = {
  autoConfig: true,   // If false, you'll need to call pageView manually
  debug: true,
};

// Initialize Facebook Pixel outside of useEffect to avoid re-initialization
ReactPixel.init('490105493804058', {}, options);

const App = () => {

  const location = useLocation();
  const isHome = location.pathname === '/';
  const isTour = location.pathname === '/virtualTour';
  const isLandingPage = ['/Skinoffer', '/Hairoffer', '/thankYou'].includes(location.pathname);

  useEffect(() => {
    setTimeout(() => {
      ReactPixel.pageView(); // Track PageView event
    }, 2000);
  }, [location.pathname]);

  if (!location.pathname.includes("admin")) {
    localStorage.removeItem("token");
  }

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <ScrollToTop />
        {!isTour && !isLandingPage && <Navbar isHome={isHome} />}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/virtualTour" element={<VirtualTour />} />
          <Route exact path="/appointment" element={<Appointment />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/services/:serviceId" element={<ServicesDetails />} />
          <Route exact path="/:routeId" element={<RouteToPage />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route exact path="/blogs/:blogId" element={<BlogDetails />} />
          <Route exact path="/thankYou" element={<ThankYouCard />} />
          <Route exact path="/dermatologist-in-hyderabad" element={<DoctorDetails expert={"ravali"} />} />
          <Route exact path="/dermatologist-in-hyderabad-1" element={<DoctorDetails expert={"aashna"} />} />
          <Route exact path="/dermatologist-in-hyderabad-2" element={<DoctorDetails expert={"hemant"} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {!isTour && !isLandingPage && <>
          <Footer />
          <FooterStrip />
        </>}
      </Suspense>
    </>
  );
}

export default App;
