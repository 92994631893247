import React from 'react';
import { NavLink } from 'react-router-dom';

import './Navbar.css'

function Navbar({ isHome }) {
    const [isShow, setIsShow] = React.useState(false);
    const navbarClass = isHome ? 'nav-position navigation-section mt-0' : 'navigation-section mt-0';

    const handleNavbarTogglerClick = () => {
        setIsShow(!isShow);
    }

    const handleNavLinkClick = () => {
        setIsShow(false);
    }

    return (
        <section id="navigation" className={navbarClass}>
            <header className="container-fluid p-0">
                <div className="header-wrapper d-flex justify-content-between">
                    <div className="clinic-logo">
                        <NavLink className="nav-link" aria-current="page" to={"/"}>
                            <img src={require("../Assets/img/logo/logo.png")} alt="logo" />
                        </NavLink>
                    </div>
                    <div className="header-right">
                        <div className="header-right-top d-flex justify-content-end" id="header-right-top">
                            <div className="social-media-icon align-items-center mt-0 d-flex p-10">
                                <a href="https://www.facebook.com/neya.clinic/" target='_blank'>
                                    <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.instagram.com/neya_skinclinic/" target='_blank'>
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.linkedin.com/company/neya-dermatology-aesthetics/" target='_blank'>
                                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div className="border-divider"></div>
                            <div className="call-us">
                                <p>
                                    <strong className={isHome ? "color-secondary" : "color-primary"}>Call Us -</strong>
                                    <span>
                                        <a href="tel:7832009999" className='color-primary' target='_blank'>7832-00-9999</a>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="border-div" id="border-div"></div>

                        <nav className="cust_navbar navbar-expand-lg navbar-light">
                            <div className="container-fluid">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                                    aria-label="Toggle navigation" onClick={handleNavbarTogglerClick}>
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="d-flex">
                                    <div className="call-us-icon">
                                        <a href="tel:7832009999">
                                            <i className="fa fa-phone-square" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                    <div className={`collapse navbar-collapse justify-content-end ${isShow ? 'show' : ''}`} id="navbarNav">
                                        <ul className="nav justify-content-end navbar-nav mt-15 d-block mt-2">
                                            <li className="nav-item d-inline-block">
                                                <NavLink className="nav-link" aria-current="page" to={"/"} onClick={handleNavLinkClick}>Home</NavLink>
                                            </li>
                                            <li className="nav-item d-inline-block">
                                                <NavLink className="nav-link" to={"/about"} onClick={handleNavLinkClick}>About Us</NavLink>
                                            </li>
                                            <li className="nav-item d-inline-block">
                                                <NavLink className="nav-link" to={"/virtualTour"} onClick={handleNavLinkClick}>Virtual Tour</NavLink>
                                            </li>
                                            <li className="nav-item d-inline-block">
                                                <NavLink className="nav-link" to={"/appointment"} onClick={handleNavLinkClick}>Appointment</NavLink>
                                            </li>
                                            <li className="nav-item d-inline-block">
                                                <NavLink className="nav-link" to={"/services"} onClick={handleNavLinkClick}>Services</NavLink>
                                            </li>
                                            <li className="nav-item d-inline-block">
                                                <NavLink className="nav-link" to={"/blogs"} onClick={handleNavLinkClick}>Blog</NavLink>
                                            </li>
                                            <li className="nav-item d-inline-block">
                                                <NavLink className="nav-link" to={"/contact"} onClick={handleNavLinkClick}>Contact</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </section>
    );
}

export default Navbar;
